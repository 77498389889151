export const PHOTO_REQUIREMENTS: string[] = [
  'Цветное или чёрно-белое',
  'Овал лица занимает 70—80% от фото',
  'Лицо смотрит на камеру',
  'Выражение лица нейтральное',
  'Всё лицо чёткое, в фокусе',
  'Фон белый, ровный, без посторонних предметов и теней',
  'Фото соответствует возрасту',
  'Если религия обязывает покрывать голову, овал лица должно быть чётко видно. В иных случаях головных уборов быть не должно',
  'Формат JPEG, JPG, PNG, размер не больше 400 Кб'
];
